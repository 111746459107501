import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import PatekCategoryBtns from '../../components/preowned/PatekCategoryBtns'

// markup
const PatekAquanaut = () => {
  const data = useStaticQuery(
    graphql`
      query queryAquanaut {
        products: allStrapiProduct(
          filter: {
            brand: { eq: "Patek Philippe" }
            model: { regex: "/Aquanaut/" }
            qty: { gt: 0 }
          }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Patek Philippe Aquanaut Watches for Sale'}
      canonical={'/fine-watches/patek-philippe/aquanaut/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Patek Philippe Aquanaut Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/patek-philippe/aquanaut/">
              <StaticImage
                src="../../images/preowned/preowned-patek-philippe-aquanaut-banner.png"
                alt="Pre-Owned Certified Used Patek Philippe Aquanaut Watches Header"
                aria-label="Used Patek Philippe Aquanaut Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE PATEK PHILIPPE AQUANAUT
              WATCHES AT GRAY AND SONS
            </h1>
            <h2>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED PATEK PHILIPPE AQUANAUT WATCHES WITH
              GRAY AND SONS JEWELERS
            </h2>
            <p>
              Gray and Sons is the #1 real watch and jewelry store located in Surfside, Miami,
              Florida. Across from Bal Harbour Shops. Gray and Sons is known for our real watch and
              jewelry experts on-site, open six days a week, and that we're real, not a virtual
              store. Our customers say we are the #1 Miami used watch buyer and seller proudly
              assisting for the past 42 years. We're sellers and buyers of pre-owned, used and new
              Patek Philippe Aquanaut watches in Miami, Miami Beach, Coral Gables, Coconut Grove,
              South Beach, Bal Harbour, Surfside, Sunny Isles , Aventura, Fort Lauderdale and many
              more areas. Check out our Patek Philippe Annual Calender videos on youtube too.
              <br />
              <br />
              Gray and Sons is the place to go if you want to buy or sell a Patek Philippe Aquanaut
              Watch. Would you like to CHAT with a live watch expert? Rich is a live Patek Philippe
              Watch expert decision makers and ready to work with you now. Click{' '}
              <a href="/chat-with-rich">Here</a>
              <br />
              <br />
              <b>About Patek Philippe Aquatnaut Watches</b>
              <br />
              <br />
              One of the most popular luxury watches in the current market, the Patek Philippe
              Aquanaut watch is a must-have among Patek collectors and enthusiasts of high-end
              sports watches. Patek Philippe launched the Aquanaut collection in 1997, which was
              inspired by the Nautilus but more streamlined in design to appeal to a younger
              audience. Over the next two-plus decades, the Patek Aquanaut has grown into a crowd
              favorite, admired for it its recognizable cushion-shaped case with an eight-sided
              bezel, embossed dial, and sporty textured Tropical strap.
              <br />
              <br />
              <b>Buying Pre-Owned Patek Philippe Aquatnaut Watches</b>
              <br />
              <br />
              As one of Patek’s most popular designs, pre-owned Aquanaut watches are highly
              sought-after and the brand has made numerous references over the years. Secondhand
              Patek Philippe Aquanaut watches range from full steel editions to precious metal
              variants, smaller cases for women to larger cases for men, and classic time and date
              dials to complicated models, such as chronographs. There are even quartz and automatic
              used Patek Aquanaut watches to choose from. If you’re looking to buy a certified
              pre-owned Patek Aquanaut watch, t hen browse Gray &amp; Sons’ collection of fine
              timepieces. Not only does Gray &amp; Sons have an inventory of second hand Aquanaut
              watches in stock right now, but as an independent Patek Philippe watch dealer, it also
              offers fantastic price flexibility. Get in touch with one of our decision-makers to
              buy a preowned Patek Philippe Aquanaut watch now, before they’re all gone. We offer
              certified authentic and certfied pre-owned watches.
              <br />
              <br />
              However, if you’ve recently decided that I want to sell my used Patek Philippe
              Aquanaut watch for cash, then contact Gray &amp; Sons immediately to get the best
              offer. To sell your Patek Philippe Aquanaut for cash, visit{' '}
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com</a> and you will get
              the price for your watch. Our happy clients agree that we pay top dollar to anyone
              selling a used Patek Philippe Aquanaut watch. Some popular refrence numbers for the
              Patek Philippe Aquatnaut are: Ref. 5060, Ref. 5064, Ref. 5065, Ref. 4960, Ref. 5065,
              Ref. 5066.
              <br />
              <br />
              <br />
              <br />
              <b>Servicing Pre-Owned Patek Philippe Aquatnaut Watches</b>
              <br />
              <br />
              At Gray and Sons, we are proud to operate a full-service Patek Philippe Aquanaut watch
              repair shop on our premises, complete with skilled Swiss watch-trained watchmakers.
              Given their collectability and value, only trust expert watchmakers to service or
              repair your secondhand Patek Aquanaut watch. From complicated repairs to routine
              maintenance and cleaning to battery replacements, Gray and Son’s independent Patek
              Philippe repair center has been designated the best in the business by our satisfied
              customers. Visit <a href="/">www.grayandsons.com</a> to get in contact with our watch
              repair center.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/patek-philippe/aquanaut/">
                <button>SHOP PATEK PHILIPPE AQUANAUT WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK PATEK PHILIPPE WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <PatekCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PatekAquanaut
